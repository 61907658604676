<template>
  <v-row justify="center">
    <v-dialog
      v-model="terminationDialog"
      width="900px"
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
            <v-btn
            color="primary"
            text
            v-bind="attrs"
            v-on="on"
            small
            >
            <v-icon>mdi-checkbox-marked-outline</v-icon>
            </v-btn>
      </template>
        <v-card>
            <v-card-title>
            <span class="text-h5" id="dialog_title">Deletion Approval</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <h4>Reason for Deletion</h4>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                        <v-textarea
                            readonly
                            auto-grow
                            outlined
                            v-model="item.sp_termination.reason"
                        ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <section>
                          <v-btn
                            class="mr-4"
                            color="primary"
                            style="float:right;"
                            @click="approve"
                            >
                            <b>Approve</b>
                          </v-btn>
                        </section>
                        <section>
                          <v-btn
                            class="mr-4"
                            outlined
                            color="error"
                            style="float:right;"
                            @click="disapprove"
                            >
                            <b>Disapprove</b>
                          </v-btn>
                        </section>
                        <section >
                          <v-btn
                            class="mr-4"
                            outlined
                            color="primary"
                            style="float:left;"
                            @click="cancel"
                            >
                            <b>Cancel</b>
                          </v-btn>
                        </section>
                      </v-col>
                    </v-row>
              </v-container>
          </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    props: ["item"],
    data () {
      return {
        terminationDialog: false,
      }
    },
    methods:{
        cancel(){
          this.terminationDialog = false
        },
         async approve(){
          const res = await this.$store.dispatch('salesProspecting/doApproveTermination', this.item.id)
            if (res.status === 200){
              this.terminationDialog = false
              location.reload();
            }
        },
        async disapprove(){
          const res = await this.$store.dispatch('salesProspecting/doRejectTermination', this.item.id)
            if (res.status === 200){
              this.terminationDialog = false
              location.reload();
            }

        }
    },
  }
</script>

<style scoped>
    #dialog_title{
        color: rgb(20, 20, 137);
        font-weight: 600;
    }
</style>